import { defineComponent, PropType } from 'vue';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';
import { PresentationCardItem } from '@/common/primary/presentation-card/PresentationCardItem.ui';
import { ExclusivePriceVue } from '@/fairplayer/primary/marketplace/exclusive-price';

export default defineComponent({
  name: 'PresentationCard',

  components: { FairplayerImageVue, ExclusivePriceVue },

  props: {
    item: {
      type: Object as PropType<PresentationCardItem>,
      required: true,
    },
  },
});
