.fp-vertical-spacing--line
  router-link.fp-link.-no-text-decoration(:to='{name: \'chatPage\', params: { memberId: conversation.interlocutorId }}')
    .fp-horizontal-spacing.-s-sm.-items-center
      .fp-horizontal-spacing--column
        .fp-image.-circled
          FairplayerImageVue(:url='conversation.interlocutorPictureUrl' :fallback-image='FairplayerFallbackImageUi.FAN')
      .fp-horizontal-spacing--column.-expand
        .fp-vertical-spacing.-s-xs
          .fp-vertical-spacing--line
            span.fp-text.-text-semi-bold(data-test='interlocutor-name') {{ conversation.interlocutorFirstName }} {{ conversation.interlocutorLastName }}
          .fp-vertical-spacing--line
            .fp-text.-color-shade-500.-lines-1.-text-sm(data-test='last-message')
              span(v-if='!lastMessage.received' data-test='you') {{ t('chat.you') }}&nbsp;
              | {{ lastMessage.text }}
      .fp-horizontal-spacing--column(v-if='conversation.numberOfUnreadMessages > 0' data-test='number-of-unread-message')
        .fp-chip {{ conversation.numberOfUnreadMessages }}
