include ../../../common/primary/icon/icon.mixin.pug

input#footer-collapsible(type='checkbox' name='collapse' v-model='pageFooterCollapsed')
label.fp-page--content-footer--trigger(for='footer-collapsible')
  +fp-icon('chevron-down', {size: '12'})
.fp-page--content-footer--hideable
  .fp-responsive-grid.-cols4.-cols1-up-md(data-test='footer')
    .fp-responsive-grid--column
      .fp-vertical-spacing.-s-sm
        .fp-vertical-spacing--line(v-if='isFoundation')
          span.fp-text.-text-sm(data-test='foundation-presentation') {{ t('tos.fairplayerSolution.foundation') }}
        .fp-vertical-spacing--line(v-else)
          span.fp-text.-text-sm(data-test='marketplace-presentation') {{ t('tos.fairplayerSolution.marketplace') }}
    .fp-responsive-grid--column
      .fp-vertical-spacing.-s-sm
        .fp-vertical-spacing--line
          span.fp-text.-text-sm.-text-semi-bold {{ t('tos.contact') }}
        .fp-vertical-spacing--line
          MailToContactVue
    .fp-responsive-grid--column
      .fp-vertical-spacing
        .fp-vertical-spacing--line(v-if='showLanguageSelector')
          LanguageSelectorVue
        .fp-vertical-spacing--line
          ThemeSelectorVue
    .fp-responsive-grid--column
      .fp-vertical-spacing.-s-sm
        .fp-vertical-spacing--line
          span.fp-text.-text-sm.-text-semi-bold {{ t('tos.display') }}
        .fp-vertical-spacing--line(v-for='id in documents' :key='id')
          a.fp-link.-text-sm.-text-regular(data-test='footer-document' target='_blank' :href='`${staticFilesUri}/tos/${id}.pdf`') {{ t(`tos.${id}.title`) }}
        .fp-vertical-spacing--line(v-if='!referralNeeded')
          a.fp-link.-text-sm.-text-regular(data-test='consent-modal' @click='displayConsentModal()') {{ t('consentModal.showModalBtn') }}
.fp-text.-text-sm(data-test='dated-mentions') {{ datedMentions }}
