import { defineComponent, inject, PropType, useSlots } from 'vue';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { useI18n } from 'vue-i18n';
import { FairplayerButtonVue } from '@/common/primary/button';
import { alertBusKey } from '@/common/domain/alert/AlertBus';
import { globalWindowKey } from '@/common/domain/Window';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { ClubWeb3Model } from '@/common/domain/club/ClubWeb3Model';
import { useRouter } from 'vue-router';
import { loginContextRepositoryKey } from '@/common/domain/login/LoginContextRepository';

const UNHANDLED_USER_AGENT_PARTS = ['instagram', 'facebook'];

export default defineComponent({
  name: 'Login',
  components: { FairplayerButtonVue },

  props: {
    redirectToKyc: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    expandButton: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    icon: {
      type: String as PropType<string>,
      required: false,
      default: 'log-in',
    },
    translateKey: {
      type: String as PropType<string>,
      required: false,
      default: 'homepage.login',
    },
  },

  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const slots = useSlots();

    const authentication = inject(authenticationKey)!;
    const alertBus = inject(alertBusKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const globalWindow = inject(globalWindowKey)!;
    const loginContextRepository = inject(loginContextRepositoryKey)!;

    const club = clubRepository.getCurrentClub();

    const login = () => {
      if (UNHANDLED_USER_AGENT_PARTS.some(userAgentPart => globalWindow.navigator.userAgent?.toLowerCase().includes(userAgentPart))) {
        alertBus.alert({ message: 'errors.login.unhandledBrowser', type: 'warning' });

        return;
      }

      if (club.web3Model === ClubWeb3Model.OFF_CHAIN) {
        return storeLoginContextThenGoToLogin();
      }
      return authentication.login(props.redirectToKyc);
    };

    const storeLoginContextThenGoToLogin = () => {
      loginContextRepository.storeLoginContext({
        redirectUrl: window.location.href,
        kycNeeded: false,
      });
      return router.push({ name: 'login' });
    };

    return {
      login,
      t,
      slots,
    };
  },
});
