include ../../../../../common/primary/icon/icon.mixin.pug

.fp-card
  .fp-vertical-spacing.-s-sm
    .fp-vertical-spacing--line.-justify-center
      .fp-image.-circled.-xl
        FairplayerImageVue(:url='member.pictureUrl', :fallback-image='FairplayerFallbackImageUi.FAN' data-test='picture')
    .fp-vertical-spacing--line.-align-center
      span.fp-text.-text-semi-bold.-lines-2(data-test='name') {{ member.firstName }} {{ member.lastName }}
    .fp-vertical-spacing--line.-align-center
      router-link.fp-link.-secondary(v-if='!member.me' :to='{name: \'chatPage\', params: { memberId: member.id }}' data-test='contact') {{ t('members.contact') }}
      span.fp-text.-secondary.-color-shade-500(v-else data-test='you') {{ t('members.you') }}
