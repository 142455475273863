import { BackendCaller } from '@/common/secondary/BackendCaller';
import { BackendFan } from '@/fairplayer/secondary/fan/BackendFan';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { FanPersonalInfo } from '@/fairplayer/domain/fan/FanPersonalInfo';
import { fromFanPersonalInfo } from '@/fairplayer/secondary/fan/BackendFanPersonalInfo';
import { FanToCreate } from '@/fairplayer/domain/fan/FanToCreate';
import { fromFanToCreate } from '@/fairplayer/secondary/fan/BackendFanToCreate';
import { Username } from '@/common/domain/Username';

export class BackendFanRepository {
  private storedFan: BackendFan | undefined;

  constructor(private backendCaller: BackendCaller) {}

  async registerFan(fanToCreate: FanToCreate): Promise<void> {
    await this.backendCaller.post<void>(`api/clubs/${fanToCreate.clubSlug}/fans`, fromFanToCreate(fanToCreate));
  }

  async getFan(clubSlug: ClubSlug, forceRefresh = false): Promise<BackendFan> {
    if (this.storedFan && !forceRefresh) {
      return this.storedFan;
    }
    const fan = (await this.backendCaller.get<BackendFan>(`api/clubs/${clubSlug}/fans/me`)).data;

    this.storedFan = fan;

    return fan;
  }

  async sendPersonalFanInfoFor(fanPersonalInfo: FanPersonalInfo, clubSlug: ClubSlug, fanUsername: Username): Promise<BackendFan> {
    return this.backendCaller
      .put<BackendFan>(`api/clubs/${clubSlug}/fans/${encodeURIComponent(fanUsername)}/personal-info`, fromFanPersonalInfo(fanPersonalInfo))
      .then(fan => fan.data);
  }

  async acceptTos(clubSlug: ClubSlug): Promise<void> {
    await this.backendCaller.post<void>(`api/clubs/${clubSlug}/fans/me/tos-acceptances`);
  }
}
