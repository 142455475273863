import { authenticationKey } from '@/common/domain/auth/Authentication';
import { NotFoundVue } from '@/common/primary/not-found';
import { exclusiveNotFoundUi } from '@/common/primary/not-found/NotFound.ui';
import { Fan } from '@/fairplayer/domain/fan/Fan';
import { fanRepositoryKey } from '@/fairplayer/domain/fan/FanRepository';
import { walletRepositoryKey } from '@/fairplayer/domain/fan/wallet/WalletRepository';
import { PageVue } from '@/fairplayer/primary/page';
import { Loader } from '@/loader/primary/Loader';
import { computed, inject, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { ExclusiveDetailVue } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';
import { ExclusiveUi, fromExclusive } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { exclusiveRepositoryKey } from '@/fairplayer/domain/exclusive/ExclusiveRepository';
import { useRoute, useRouter } from 'vue-router';
import { Optional } from '@/common/domain/Optional';
import { LinkedClubBannerVue } from '@/fairplayer/primary/club/linked-club-banner';
import { HiddenExclusiveVue } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/hidden-exclusive';
import { RecommendedExclusivesVue } from '@/fairplayer/primary/marketplace/exclusive-page/recommended-exclusives';
import { FairplayerButtonVue } from '@/common/primary/button';
import { ExclusivePriceVue } from '@/fairplayer/primary/marketplace/exclusive-price';
import { loggerKey } from '@/common/domain/Logger';
import { bottomModalBusKey } from '@/common/domain/bottom-modal/BottomModalBus';
import { confirmPurchaseBottomModal } from '@/common/primary/bottom-modal/BottomModals';
import { Wallet } from '@/fairplayer/domain/Wallet';
import { Exclusive } from '@/fairplayer/domain/exclusive/Exclusive';
import { fromWallet, WalletUi } from '@/fairplayer/primary/wallet/Wallet.ui';
import { fromExclusiveAndFanBalance } from '@/fairplayer/domain/fan/order/OrderCost';
import { fromOrderCost } from '@/fairplayer/primary/marketplace/exclusive-page/OrderCost.ui';
import { addBidModal } from '@/common/primary/modal/Modals';
import { modalBusKey } from '@/common/domain/modal/ModalBus';
import { globalWindowKey } from '@/common/domain/Window';
import { pageRedirecterKey } from '@/common/primary/PageRedirecter';

export default {
  name: 'ExclusivePage',

  components: {
    ExclusiveDetailVue,
    ExclusivePriceVue,
    FairplayerButtonVue,
    HiddenExclusiveVue,
    LinkedClubBannerVue,
    NotFoundVue,
    PageVue,
    RecommendedExclusivesVue,
  },

  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();

    const authentication = inject(authenticationKey)!;
    const modalBus = inject(modalBusKey)!;
    const bottomModalBus = inject(bottomModalBusKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const logger = inject(loggerKey)!;
    const exclusiveRepository = inject(exclusiveRepositoryKey)!;
    const fanRepository = inject(fanRepositoryKey)!;
    const walletRepository = inject(walletRepositoryKey)!;
    const globalWindow = inject(globalWindowKey)!;
    const pageRedirecter = inject(pageRedirecterKey)!;

    const isAuthenticated = ref(Loader.loading<boolean>());
    const wallet = ref<Wallet>();
    const walletUi = ref(Loader.loading<WalletUi | null>());
    const exclusive = ref(Loader.loading<Exclusive>());
    const exclusiveUi = ref(Loader.loading<ExclusiveUi>());
    const fan = ref(Loader.loading<Fan | null>());
    const displayExclusiveError = ref<boolean>(false);
    const isBuying = ref(false);

    const buyButtonDisabled = computed(
      () => exclusiveUi.value.value().isOutOfStock || exclusiveUi.value.value().isUpcoming || !exclusiveUi.value.value().visible
    );
    const currentExclusiveSlug = computed(() => route.params.exclusiveSlug as string);
    const currentClubSlug = clubRepository.getCurrentSlug();
    const club = fromClub(clubRepository.getCurrentClub());
    const isInfinite = club.slug.includes('infinite');

    const loadAuthenticated = async (): Promise<void> => {
      let authenticatedResponse = false;
      try {
        authenticatedResponse = await authentication.isAuthenticated();
      } catch (error: any) {
        logger.error('Failed to authenticate', error);
      } finally {
        isAuthenticated.value.loaded(authenticatedResponse);
      }
    };

    const loadFan = async (): Promise<void> => {
      fan.value.loaded(await fanRepository.getForClub(clubRepository.getCurrentClub()));
    };

    const loadWallet = async (): Promise<void> => {
      wallet.value = await walletRepository.getForClub(currentClubSlug);
      walletUi.value.loaded(fromWallet(wallet.value));
    };

    const loadUnauthenticatedWalletAndFan = () => {
      walletUi.value.loaded(null);
      fan.value.loaded(null);
    };

    const loadWalletAndFan = async () => {
      if (isAuthenticated.value.value()) {
        await Promise.all([loadWallet(), loadFan()]);
      } else {
        loadUnauthenticatedWalletAndFan();
      }
    };

    const loadOnlyFan = async () => {
      if (isAuthenticated.value.value()) {
        await loadFan();
      } else {
        fan.value.loaded(null);
      }
      walletUi.value.loaded(null);
    };

    const loadExclusive = async (): Promise<void> => {
      try {
        const retrievedExclusive = (await exclusiveRepository.getBySlug(club.slug, currentExclusiveSlug.value)).orElseThrow();
        exclusive.value.loaded(retrievedExclusive);
        exclusiveUi.value.loaded(fromExclusive(retrievedExclusive, clubRepository.getCurrentClub()));
      } catch (error: any) {
        logger.error('Failed to retrieve exclusive', error);
        displayExclusiveError.value = true;
      }
    };

    const openConfirmPurchaseBottomModal = () => {
      const modal = confirmPurchaseBottomModal();
      bottomModalBus.open({
        component: modal,
        titleKey: 'marketplace.confirmPurchase.title',
        icon: 'coins',
        options: {
          exclusive: exclusiveUi.value.value(),
          fan: fan.value,
          isAuthenticated: isAuthenticated.value.value(),
          wallet: walletUi.value.value(),
          orderCost: fromOrderCost(fromExclusiveAndFanBalance(exclusive.value.value(), wallet.value)),
          createFiatOrder,
          createCoinsOrder,
        },
      });
    };

    const buy = async () => {
      if (club.isFoundation) {
        isBuying.value = true;
        createFiatOrder();
      } else {
        openConfirmPurchaseBottomModal();
      }
    };

    const addBid = () => {
      modalBus.open({
        component: addBidModal(),
        options: {
          clubSlug: club.slug,
          exclusiveSlug: exclusive.value.value().slug,
          minimalAmount: exclusive.value.value().auction.orElseThrow().currentPrice.totalCost.value + 100,
          added: loadExclusive,
        },
      });
    };

    const createFiatOrder = (): void => {
      const currentLocation = globalWindow.location.href.split('?')[0];
      exclusiveRepository
        .createFiatOrder({ slug: clubRepository.getCurrentSlug(), exclusiveId: exclusive.value.value().id, currentLocation })
        .then(({ url }) => pageRedirecter.navigateTo(url))
        .catch(error => {
          logger.error('Failed to create fiat order', error);
          isBuying.value = false;
        })
        .finally(() => {
          bottomModalBus.close();
        });
    };

    const createCoinsOrder = (): void => {
      exclusiveRepository
        .createCoinsOrder(clubRepository.getCurrentSlug(), exclusive.value.value().id)
        .then(() => router.push({ name: 'purchaseConfirmed' }))
        .catch(error => {
          logger.error('Failed to create coins order', error);
          isBuying.value = false;
        })
        .finally(() => {
          bottomModalBus.close();
        });
    };

    onMounted(async () => {
      await Promise.all([loadAuthenticated(), loadExclusive()]);

      if (!club.coinsEnabled) {
        await loadOnlyFan();
      } else {
        await loadWalletAndFan();
      }
    });

    watch(
      () => [currentExclusiveSlug.value],
      () => {
        Optional.ofEmpty(currentExclusiveSlug.value).ifPresent(() => loadExclusive());
      }
    );

    return {
      displayExclusiveError,
      createCoinsOrder,
      createFiatOrder,
      exclusiveNotFoundUi,
      addBid,
      buy,
      buyButtonDisabled,
      club,
      fan,
      isAuthenticated,
      isInfinite,
      isBuying,
      exclusive,
      exclusiveUi,
      walletUi,
      t,
    };
  },
};
