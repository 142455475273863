import { Authentication } from '@/common/domain/auth/Authentication';
import { FanIdentity } from '@/common/domain/auth/FanIdentity';
import { Observable } from '@/common/domain/Observable';
import { Optional } from '@/common/domain/Optional';
import { Fiat } from '@/common/domain/token/Fiat';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { Fan } from '@/fairplayer/domain/fan/Fan';
import { FanPersonalInfo } from '@/fairplayer/domain/fan/FanPersonalInfo';
import { FanRepository } from '@/fairplayer/domain/fan/FanRepository';
import { BackendFan } from '@/fairplayer/secondary/fan/BackendFan';
import { toFanPersonalInfo } from '@/fairplayer/secondary/fan/BackendFanPersonalInfo';
import { BackendFanRepository } from '@/fairplayer/secondary/fan/BackendFanRepository';
import { KriptownViewerRepository } from '@/fairplayer/secondary/fan/KriptownViewerRepository';
import { Club } from '@/fairplayer/domain/club/Club';
import { FanToCreate } from '@/fairplayer/domain/fan/FanToCreate';
import { KycStatus } from '@/fairplayer/domain/fan/KycStatus';

const extractIdentity = (
  personalInfo: Optional<FanPersonalInfo>,
  backendPictureUrl: string | null,
  authenticatedFan: FanIdentity
): FanIdentity => {
  const pictureUrl = Optional.ofUndefinable(backendPictureUrl).or(authenticatedFan.pictureUrl);

  return personalInfo
    .map(info => ({
      firstName: info.firstName,
      lastName: info.lastName,
      name: `${info.firstName} ${info.lastName}`,
      username: authenticatedFan.username,
      email: authenticatedFan.email,
      pictureUrl,
    }))
    .orElse({ ...authenticatedFan, pictureUrl });
};

const temporarilyMapKycStatus = (kycStatus: KycStatus) => {
  if (kycStatus === ('REFERRED' as KycStatus)) {
    return KycStatus.READY_FOR_KYC;
  }

  return kycStatus;
};

const toFan = (fan: BackendFan, authenticatedFan: FanIdentity): Fan => {
  const { kycStatus, pictureUrl } = fan;
  const personalInfo = toFanPersonalInfo(fan.personalInfo);
  const identity = extractIdentity(personalInfo, pictureUrl, authenticatedFan);

  return {
    id: fan.identifier.id,
    identity,
    kycStatus: temporarilyMapKycStatus(kycStatus),
    personalInfo,
  };
};

export class FairplayerFanRepository implements FanRepository {
  constructor(
    private kriptownViewerRepository: KriptownViewerRepository,
    private backendFanRepository: BackendFanRepository,
    private authentication: Authentication
  ) {}

  async registerFan(fanToCreate: FanToCreate): Promise<void> {
    return this.backendFanRepository.registerFan(fanToCreate);
  }

  async getForClub(club: Club, forceRefresh = false): Promise<Fan> {
    return Promise.all([this.getFan(club, forceRefresh), this.authentication.authenticatedFan()]).then(([backendFan, authenticatedFan]) =>
      toFan(backendFan, authenticatedFan)
    );
  }

  private async getFan(club: Club, forceRefresh: boolean): Promise<BackendFan> {
    return await this.backendFanRepository.getFan(club.slug, forceRefresh);
  }

  async sendPersonalInfoFor(fanInfo: FanPersonalInfo, clubSlug: ClubSlug): Promise<Fan> {
    const authenticatedFan = await this.authentication.authenticatedFan();
    const backendFan = await this.backendFanRepository.sendPersonalFanInfoFor(fanInfo, clubSlug, authenticatedFan.username);

    return toFan(backendFan, authenticatedFan);
  }

  async uploadKycDocuments(files: File[]): Promise<boolean> {
    return this.kriptownViewerRepository.uploadKycDocument(files);
  }

  acceptTos(clubSlug: ClubSlug): Promise<void> {
    return this.backendFanRepository.acceptTos(clubSlug);
  }

  observableFiatNotProcessed(): Observable<Optional<Fiat>> {
    return this.kriptownViewerRepository.observableViewerBalance();
  }
}
