import * as Sentry from '@sentry/vue';
import { App } from 'vue';

export const initSentry = (app: App<Element>) => {
  if (import.meta.env.DEV) {
    console.log('Sentry is disabled in development');

    return;
  }

  if (import.meta.env.VITE_GIT_BRANCH !== 'main') {
    console.log(`Sentry is disabled in branch (${import.meta.env.VITE_GIT_BRANCH})`);

    return;
  }

  Sentry.init({
    app,
    integrations: [Sentry.captureConsoleIntegration({ levels: ['error'] })],
    environment: import.meta.env.VITE_ENV_SLUG,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    tracesSampleRate: 1.0, // Capture 100% of the transactions
  });
};
