import { FairplayerButtonVue } from '@/common/primary/button';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { Fan } from '@/fairplayer/domain/fan/Fan';
import { fanRepositoryKey } from '@/fairplayer/domain/fan/FanRepository';
import { orderRepositoryKey } from '@/fairplayer/domain/fan/order/OrderRepository';
import { OrderListVue } from '@/fairplayer/primary/order-list';
import { fromOrder, OrderUi } from '@/fairplayer/primary/order-list/Order.ui';
import { PageVue } from '@/fairplayer/primary/page';
import { Loader } from '@/loader/primary/Loader';
import { inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { LogoutVue } from '@/common/primary/auth/logout';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';
import { FairplayerFallbackImageUi } from '@/common/primary/fairplayer-image/FairplayerFallbackImage.ui';
import { DonationListVue } from '@/fairplayer/primary/profile/donation-list';
import { donationRepositoryKey } from '@/fairplayer/domain/donation/DonationRepository';
import { fanLanguageRepositoryKey } from '@/common/domain/FanLanguageRepository';
import { DonationUi, fromDonation } from '@/fairplayer/primary/profile/donation-list/Donation.ui';
import { ReferralListVue } from '@/fairplayer/primary/profile/referral-list';

export default {
  name: 'Profile',

  components: {
    FairplayerImageVue,
    FairplayerButtonVue,
    OrderListVue,
    DonationListVue,
    ReferralListVue,
    PageVue,
    LogoutVue,
  },

  setup() {
    const { t } = useI18n();

    const clubRepository = inject(clubRepositoryKey)!;
    const donationRepository = inject(donationRepositoryKey)!;
    const fanRepository = inject(fanRepositoryKey)!;
    const fanLanguageRepository = inject(fanLanguageRepositoryKey)!;
    const orderRepository = inject(orderRepositoryKey)!;

    const club = clubRepository.getCurrentClub();
    const fanLanguage = fanLanguageRepository.getCurrentLanguage();
    const isMarketplace = fromClub(club).isMarketplace;
    const isFoundation = fromClub(club).isFoundation;

    const donationList = ref(Loader.loading<DonationUi[]>());
    const fan = ref(Loader.loading<Fan>());
    const orderList = ref(Loader.loading<OrderUi[]>());

    onMounted(async () => {
      if (isMarketplace) {
        await Promise.all([retrieveFan(), retrieveOrders()]);
      }
      if (isFoundation) {
        await Promise.all([retrieveFan(), retrieveOrders(), retrieveDonations()]);
      }
    });

    const retrieveFan = async () => {
      fan.value.loaded(await fanRepository.getForClub(club));
    };

    const retrieveOrders = async () => {
      orderList.value.loaded(
        await orderRepository.listFor(clubRepository.getCurrentSlug()).then(orders => orders.map(order => fromOrder(order, fanLanguage)))
      );
    };

    const retrieveDonations = async () => {
      donationList.value.loaded(
        await donationRepository
          .listFor(clubRepository.getCurrentSlug())
          .then(donations => donations.map(donation => fromDonation(donation, fanLanguage)))
      );
    };

    return {
      FairplayerFallbackImageUi,
      fan,
      orderList,
      donationList,
      isMarketplace,
      isFoundation,
      t,
    };
  },
};
