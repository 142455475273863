import { authenticationKey } from '@/common/domain/auth/Authentication';
import { pageRedirecterKey } from '@/common/primary/PageRedirecter';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { fanRepositoryKey } from '@/fairplayer/domain/fan/FanRepository';
import { PageVue } from '@/fairplayer/primary/page';
import { inject, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { LogoutVue } from '@/common/primary/auth/logout';
import { loginContextRepositoryKey } from '@/common/domain/login/LoginContextRepository';
import { LoginFormVue } from '@/common/primary/auth/login-form';
import { ToastsListVue } from '@/fairplayer/primary/toasts/toasts-list';

const APPLICATION_BASE_URL = window.location.origin;

export default {
  name: 'LoginRedirectCallback',

  components: { PageVue, LoginFormVue, ToastsListVue, LogoutVue },

  setup() {
    const { t } = useI18n();

    const authentication = inject(authenticationKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const fanRepository = inject(fanRepositoryKey)!;
    const loginContextRepository = inject(loginContextRepositoryKey)!;
    const pageRedirecter = inject(pageRedirecterKey)!;

    const getRedirectUrl = (): string => {
      const loginContext = loginContextRepository.retrieveLoginContext();

      return loginContext.redirectUrl || APPLICATION_BASE_URL;
    };

    onMounted(async () => {
      Promise.all([clubRepository.retrieveClub(), authentication.authenticatedFan()]).then(async ([club, fanIdentity]) => {
        await fanRepository.registerFan({ clubSlug: club.slug, pictureUrl: fanIdentity.pictureUrl });

        await pageRedirecter.navigateTo(getRedirectUrl());
      });
    });

    return {
      t,
    };
  },
};
