include ../../../../../common/primary/icon/icon.mixin.pug

.fp-exclusive-detail(data-test='exclusive-detail')
  .fp-exclusive-detail--image
    .fp-display
      .fp-display--image
        ExclusiveMediaVue(:media='medias[selectedMedia]' @dblclick='highlightMedia')
        .fp-display--image-mask(v-if='exclusive.isOutOfStock || exclusive.isUpcoming')
          .fp-badge(v-if='exclusive.isUpcoming && exclusive.upcomingDate' data-test='upcoming-exclusive-badge') {{ t('marketplace.upcomingExclusiveWithDate', { date: d(exclusive.upcomingDate.toDate(), { month: 'long', day: 'numeric' })}) }}
          .fp-badge(v-else-if='exclusive.isUpcoming' data-test='upcoming-exclusive-badge-without-date') {{ t('marketplace.upcomingExclusive') }}
          .fp-badge(v-else data-test='out-of-stock-badge') {{ t('marketplace.outOfStock') }}
        .fp-display--left-action(@click='goBack' data-test='go-back')
          +fp-icon('arrow-left', {size: '16'})
        .fp-display--right-action(@click='highlightMedia' data-test='highlight-media')
          +fp-icon('widen', {size: 16})
      .fp-display--thumbnails(v-if='medias.length > 1' data-test='exclusive-detail-thumbnails')
        template(v-for='(media, index) in medias' :key='index')
          img.fp-display--thumbnail-image(v-if='media.type === \'IMAGE\'' :src='media.url' :class='{"-active": media.url === medias[selectedMedia].url}' @click='selectMedia(index)' :data-test='`exclusive-detail-thumbnail-${index}`')
          .fp-display--thumbnail-video(v-else @click='selectMedia(index)' :class='{"-active": media.url === medias[selectedMedia].url}' :data-test='`exclusive-detail-thumbnail-${index}`')
            FairplayerVideoVue(:url='media.url' expand)
  .fp-exclusive-detail--info
    .fp-vertical-spacing
      .fp-vertical-spacing--line
        ExclusiveDetailHeadingVue(:exclusive='exclusive')
      .fp-vertical-spacing.-s-xs
        .fp-vertical-spacing--line
          h2.fp-title(v-if='exclusive.auction') {{ t('marketplace.exclusiveDetail.auction.toThankYou') }}
          h2.fp-title(v-else) {{ t('marketplace.description') }}
        .fp-vertical-spacing--line
          .fp-text(v-html='exclusive.description.html' data-test='description')
      ExclusiveAuctionVue(v-if='exclusive.auction' :exclusive='exclusive')
      GiveawayCardVue
